import React from 'react';
import { useServerData } from '../../../../state/serverDataContext';
import TeaserDefault from '../TeaserDefault';
import { TeaserPropTypes } from '../../Teaser';

const TeaserMobileOnly = ({ segment, index, textBeforeImage }) => {
  const { isMobile } = useServerData(data => ({
    isMobile: data.isMobile || false,
  }));
  if (isMobile) {
    return <TeaserDefault segment={segment} index={index} textBeforeImage={textBeforeImage} firstHeadingH1 />;
  } else return null;
};

TeaserMobileOnly.propTypes = {
  ...TeaserPropTypes,
};

export default TeaserMobileOnly;
